<template>
  <div>
    <Breadcrumbs
      page_title="feedbackbox"
      :items="breadcrumbs"
      :item_no="item_no ? item_no : 0"
      :item_btn_add="true"
      :btn_text="'addfeedback'"
      @ShowAction="ShowAction"
    />

    <v-container fluid>
      <v-card style="border-radius:16px;">
        <v-container>
          <v-row class="ma-1">
            <v-col>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="items"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
              ></v-data-table>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mx-6 my-4">
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination circle v-model="page" class="pagination" :length="pageCount"></v-pagination>
              </v-col>
              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show")}}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #C0C0C0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <v-dialog v-model="sendFeedback" persistent width="600">
      <v-card class="pa-3 sendquestion">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title
            style="font-size: 20px; color: #363636;padding-bottom: 5px;padding-top: 5px;"
          >
            <v-img src="@/assets/images/Iconly-Light-Outline-Send.svg" width="25" class="QA"></v-img>
            <p class="mb-2" style="margin-left:37px;">{{ $t("feedbackbox") }}</p>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="sendFeedback = false"
              color="#424242"
              style="border-radius: 8px !important; border:1px solid rgba(66, 66, 66, 0.05); top:-8px; "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text style="padding-bottom: 5px;padding-top:5px">
            <div class="pt-1">
              <v-row class="mb-1" style="display: flex; justify-content: center;">
                <v-col cols="12" class="pb-0">
                  <p style="font-size:16px; color: #4d4f5c; font-weight:600;">{{ $t("title") }}</p>
                  <v-text-field
                    outlined
                    dense
                    :placeholder=  '$t("entertitle")'
                    hide-details="auto"
                    v-model="titleName"
                    class="rounded-lg"
                    :color="primaryColor"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <p style="font-size:16px; color: #4d4f5c; font-weight:600;">
                    {{ $t("message") }}
                  </p>
                  <v-textarea
                    outlined
                    :placeholder=  '$t("enterquestion")'
                    :rules="replyRules"
                    required
                    name="input-7-4"
                    v-model="message"
                    :color="primaryColor"
                  ></v-textarea>
                </v-col>
                <div
                  v-if="actionloading"
                  style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; display: flex; justify-content: center; align-items: center;"
                >
                  <v-progress-circular
                    v-if="actionloading"
                    indeterminate
                    class="loading-circle"
                    color="green"
                  ></v-progress-circular>
                </div>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions class="pb-3 d-flex justify-center">
            <v-btn
              class="text-capitalize white--text login-phone"
              width="100"
              color="#A6CC39"
              style="font-size: 16px "
              @click="addFeedback()"
            >{{ $t("send") }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- successDialog -->
    <v-dialog v-model="successDialog" persistent width="375">
      <v-card style="overflow: hidden !important;border-radius: 16px !important;">
        <v-card-title style="font-size: 20px; color: #363636;">
          <v-row class="mt-1">
            <v-col style="text-align: center;">
              <p class="mb-2">{{ $t("success") + " " + $t('addfeedback')}}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
       
        <v-card-actions>
          <v-btn
            class="text-capitalize mx-auto white--text"
            color="#A6CC39"
            style="font-size: 20px;border-radius: 8px;"
            @click="successDialog=false"
          >{{$t('ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  props: {
    primaryColor: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      loading: true,
      sendFeedback: false,
      successDialog: false,
      item_no: 0,
      actionloading: false,
      titleName: "",
      valid: true,
      message: "",
      replyRules: [v => !!v || "Message is required"],
      items: [],
      breadcrumbs: [
        {
          text: "feedbackbox"
        }
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }]
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor"
        },
        {
          text: this.$t("title"),
          align: "left",
          value: "title",
          class: "tableColor"
        },
        {
          text: this.$t("message"),
          align: "left",
          value: "fbReceived",
          class: "tableColor"
        },
        // {
        //   text: this.$t("employeeId"),
        //   align: "left",
        //   value: "userID",
        //   class: "tableColor"
        // },
        // {
        //   text: this.$t("fullnamesm"),
        //   align: "left",
        //   value: "name",
        //   class: "tableColor"
        // },
        {
          text: this.$t("senddate"),
          value: "sendDate",
          align: "left",
          class: "tableColor"
        }
        // {
        //   text: this.$t("Manage"),
        //   align: "center",
        //   value: "actions",
        //   class: "tableColor"
        // }
      ];
    }
  },
  mounted() {
    this.getData();
    this.getFavourite();
    this.getNotificationCount();
  },
  methods: {
    ShowAction(v) {
      if (v == "Add") {
        this.sendFeedback = true;
      }
    },
    async getData() {
      let self = this;
      let arraylist = [];
      self.loading = true;
        await self.$axios.post(
          `${self.web_url}FeedBack/GetFeedback?id=` +
            localStorage.getItem("companyID") +
            `&offset=` +
            new Date().getTimezoneOffset()
        ).then(function(res){
          
       
        arraylist = res.data.data.filter(x=>x.userID == parseInt(localStorage.getItem("UserID")));
        self.items = arraylist.map((v, i) => ({
          ...v,
          no: i + 1,
          sendDate: moment(v.sendDate).format("DD/MM/YYYY HH:mm")
        }));
        self.item_no = self.items ? self.items.length : 0;
         self.loading = false;
        });
        
    },
    async addFeedback() {
      let self = this;
      self.actionloading = true;
      if (this.$refs.form.validate()) {
        const res = await self.$axios.post(
          `${self.web_url}FeedBack/AddFeedback`,
          {
            fbReceived: self.message,
            title: self.titleName,
            userID: parseInt(localStorage.getItem("UserID")),
            // sendDate: self.sendDate.format('YYYY-MM-DD HH:mm'),
            companyId: localStorage.getItem("companyID")
          }
        );
        if (res.data.status == 0) {
          self.sendFeedback = false;
          self.successDialog = true;
          self.getData();
        }
        self.$refs.form.reset();
        self.actionloading = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-data-table-header {
  background: rgba(66, 66, 66, 0.1);
}
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
</style>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
  }
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
/* >>> .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
} */
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.QA {
  position: absolute;
  top: 18px;
}
.sendquestion {
  border-radius: 18px !important;
}
</style>

<style scoped>
.login-phone{
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before
 {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before
{
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after{
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-btn__content {
  padding-top: 3px !important;
}
</style>
